export const environment = {
  production: false,
  // network: 'testnet',
  // hashpack: 'testnet',
  // mirror_node: 'https://testnet.mirrornode.hedera.com/api/v1',

  network: 'mainnet',
  hashpack: 'mainnet',
  mirror_node: 'https://mainnet-public.mirrornode.hedera.com/api/v1/',

  launchpads: ['0.0.1485092'],
  daos: ['0.0.1485092', '0.0.1484726'],
  base_url: 'https://mainnet-public.mirrornode.hedera.com',
  middleware_api_base_url: 'https://api.decentralrealty.io',
  middleware_websocket: 'wss://websocket.decentralrealty.io',
  account_id: '0.0.1269004', //0.0.1269004
  is_dev: false
};
