import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'pairing',
    loadChildren: () => import('./modals/pairing/pairing.module').then(m => m.PairingPageModule)
  },
  {
    path: 'launchpad',
    loadChildren: () => import('./launchpad/launchpad.module').then(m => m.LaunchpadPageModule)
  },
  {
    path: 'dao',
    loadChildren: () => import('./dao/dao.module').then(m => m.DaoPageModule)
  },
  {
    path: 'smart-nodes',
    loadChildren: () => import('./smart-nodes/smart-nodes.module').then(m => m.SmartNodesPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  // {
  //   path: 'buy-ico',
  //   loadChildren: () => import('./modals/ico/buy-ico/buy-ico.module').then( m => m.BuyIcoPageModule)
  // },
  // {
  //   path: 'create-dao',
  //   loadChildren: () => import('./modals/dao/create-dao/create-dao.module').then( m => m.CreateDaoPageModule)
  // },
  {
    path: 'portfolio',
    loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountPageModule)
  },
  {
    path: 'escrow',
    loadChildren: () => import('./escrow/escrow.module').then(m => m.EscrowPageModule)
  },
  {
    path: 'escrow-gen2',
    loadChildren: () => import('./escrow-gen2/escrow-gen2.module').then(m => m.EscrowGen2PageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
