import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  fetchData() {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }

  async cryptotransfer(accountNumber: string) {

    const url = environment.base_url + `/api/v1/transactions?account.id=${accountNumber}&limit=100&order=asc&transactiontype=cryptotransfer&timestamp=gt:0`;

    return firstValueFrom(this.http.get(url, httpOptions))
      .then((data) => {
        //console.log(`Result: ` + data);
        return data;
      })

  }
  async getCryptotransfer(accountNumber: string) {

    //const url = environment.mmiddleware_api_base_url+`/transactions/${accountNumber}`;
    const url = `https://api.decentralrealty.io/transactions/ws/${accountNumber}`
    return firstValueFrom(this.http.get(url, httpOptions))
      .then((data) => {
        //console.log(`Result: ` + data);
        return data;
      })

  }

  async transection(accountNumber: any) {
    const url = environment.base_url + `/api/v1/transactions?account.id=${accountNumber}&limit=100`;
    const data = await firstValueFrom(this.http.get(url, httpOptions));
    //console.log(`Result: ` + data);
    return data;
  }

}
